import {
  EscalatedStatusIcon,
  InProgressStatusIcon,
  ResolvedStatusIcon,
} from 'src/pages/ConversationsPage/ConversationList/ConversationStatusIcons';
import { clsx } from 'clsx';
import moment from 'moment';

export const ConversationList = ({ chats = [], onSelect }) => {
  const onClick = (selectedChat) => {
    chats.forEach((chat) => {
      chat.selected = selectedChat === chat;
    });

    onSelect(selectedChat);
  };

  return (
    <>
      <ul>
        {chats.map((chat) => {
          return (
            <li
              key={chat._id}
              className={clsx(
                'py-8 px-2.5 cursor-pointer flex flex-row items-center border-collapse border border-transparent border-b-light rounded-[5px] hover:border-purple_opacity_30 max-h-[106px] justify-between gap-5',
                chat.selected && 'bg-[#FAF9FF] !border-purple_opacity_30',
              )}
              onClick={() => onClick(chat)}
            >
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                >
                  <path
                    opacity={chat.selected ? '0.8' : '0.5'}
                    d="M3.24359 4.29671H12.7564M3.24359 7.23309H9.16667M13.9231 1.00049H2.07692C1.79131 1.00049 1.51739 1.11405 1.31542 1.31618C1.11346 1.51832 1 1.79247 1 2.07833V9.26396C1 9.54982 1.11346 9.82398 1.31542 10.0261C1.51739 10.2282 1.79131 10.3418 2.07692 10.3418L3.24359 10.3413V13L7 10.3418H13.9231C14.2087 10.3418 14.4826 10.2282 14.6846 10.0261C14.8865 9.82398 15 9.54982 15 9.26396V2.07833C15 1.79247 14.8865 1.51832 14.6846 1.31618C14.4826 1.11405 14.2087 1.00049 13.9231 1.00049Z"
                    stroke="#05050D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className={'min-w-[75px] max-w-[75px]'}>
                <p
                  className={clsx(
                    'text-xs font-medium text-ebony_opacity_50',
                    chat.selected && 'text-ebony_opacity_80',
                  )}
                >
                  {moment(chat.created_at).format('lll')}
                </p>
              </div>
              <div className={'flex-1 overflow-hidden'}>
                <p
                  className={clsx(
                    'text-base text-ebony_opacity_80 font-medium overflow-ellipsis text-nowrap overflow-hidden',
                    chat.selected && 'text-ebony font-semibold',
                  )}
                >
                  {chat.subject}
                </p>
                <p
                  className={clsx(
                    'text-xs font-medium text-ebony_opacity_50',
                    chat.selected && 'text-ebony_opacity_80',
                  )}
                >
                  {chat._id}
                </p>
              </div>
              <div
                className={clsx(
                  'text-xs font-medium text-ebony_opacity_50 flex flex-row items-center gap-[5px]',
                  chat.selected && 'text-ebony_opacity_80',
                )}
              >
                <p>{chat.friendlyStatus}</p>
                {chat.status === 'open' && <InProgressStatusIcon selected={chat.selected} />}
                {['closed', 'auto_closed'].includes(chat.status) && (
                  <ResolvedStatusIcon selected={chat.selected} />
                )}
                {chat.status === 'escalated' && <EscalatedStatusIcon selected={chat.selected} />}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
