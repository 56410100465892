import { useEffect } from 'react';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { getPercentageHelper } from 'src/helpers/getPercentageHelper';

const agentColor = '#75F3FB';
const customerColor = '#A498FF';
const biColor = '#DFF800';

export const useSubscriptionProgressBarGradient = ({ progressRef, widthGap = '6px' }) => {
  const { data: account } = useGetAccountsQuery();

  const generalPlan = account?.general_plan;

  const planRequestLimit =
    generalPlan?.custom_plan_settings?.request_limit || generalPlan?.plan?.request_limit;

  const metaData = generalPlan?.meta_data?.usage_by_products;

  const coAgent = metaData?.coagent?.by_source || 0;
  const coBi = metaData?.cobi?.by_source || 0;
  const coCustomer = metaData?.cocustomer?.by_source || 0;

  const productSum = coAgent + coBi + coCustomer;

  const coAgentPercent = getPercentageHelper(coAgent, productSum);
  const coBiPercent = getPercentageHelper(coBi, productSum);
  const coCustomerPercent = getPercentageHelper(coCustomer, productSum);

  const generalPercent = getPercentageHelper(
    coAgent + coBi + coCustomer + generalPlan.credits_count,
    planRequestLimit,
  );

  const generalPercentageFull = generalPercent > 100 ? 100 : generalPercent;

  useEffect(() => {
    if (progressRef.current) {
      const percentages = [
        { percent: coCustomerPercent, color: customerColor },
        { percent: coBiPercent, color: biColor },
        { percent: coAgentPercent, color: agentColor },
      ].filter((item) => item.percent > 0);

      if (percentages.length === 0) {
        progressRef.current.style.background = 'none';
        return;
      }

      const blendPercent = 10;
      const totalBlendSpace = blendPercent * (percentages.length - 1);
      const adjustedPercentages = percentages.map((item) => ({
        ...item,
        adjustedPercent: item.percent - totalBlendSpace / percentages.length,
      }));

      let gradientStops = '';
      let cumulativePercent = 0;

      adjustedPercentages.forEach((item, index) => {
        const startPercent = cumulativePercent;
        cumulativePercent += item.adjustedPercent;

        if (index > 0) {
          const previousStop = startPercent - blendPercent;
          const nextStart = startPercent + blendPercent;

          gradientStops += `, ${adjustedPercentages[index - 1].color} ${previousStop}%`;
          gradientStops += `, ${item.color} ${nextStart}%`;
        }

        gradientStops += `, ${item.color} ${startPercent}%`;
        gradientStops += `, ${item.color} ${cumulativePercent}%`;
      });

      progressRef.current.style.width = `calc(${generalPercentageFull}% - ${widthGap})`;
      progressRef.current.style.background = `linear-gradient(90deg${gradientStops})`;
    }
  }, [generalPlan, progressRef]);
};
