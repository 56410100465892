import { clsx } from 'clsx';
import {
  EscalatedStatusIcon,
  InProgressStatusIcon,
  ResolvedStatusIcon,
} from 'src/pages/ConversationsPage/ConversationList/ConversationStatusIcons';

export const ConversationSubject = ({ chat }) => {
  return (
    <>
      <div className={'flex flex-row gap-[0.625rem] overflow-hidden items-center flex-[0.8]'}>
        <p
          className={
            'text-base-sm text-ebony font-bold overflow-ellipsis text-nowrap overflow-hidden'
          }
        >
          {chat?.subject}
        </p>
      </div>
      {chat?.status === 'open' && (
        <div
          className={clsx(
            'text-[13px] font-medium flex flex-row items-center shadow-chat-status rounded-[5px] gap-[5px] border border-[rgba(0, 156, 178, 0.05)] py-2.5 px-4 bg-[#31c2e20d]',
          )}
        >
          <p className={'text-[#009CB2]'}>{chat?.friendlyStatus}</p>
          <InProgressStatusIcon fill={'#009CB2'} selected={chat?.selected} />
        </div>
      )}
      {['closed', 'auto_closed'].includes(chat?.status) && (
        <div
          className={clsx(
            'text-[13px] font-medium flex flex-row items-center shadow-chat-status-resolved rounded-[5px] gap-[5px] border border-[#389d6112] py-2.5 px-4 bg-[#F4FFF7]',
          )}
        >
          <p className={'text-[#389D61]'}>{chat?.friendlyStatus}</p>
          <ResolvedStatusIcon fill={'#389D61'} selected={chat?.selected} />
        </div>
      )}
      {chat?.status === 'escalated' && (
        <div
          className={clsx(
            'text-[13px] font-medium flex flex-row items-center shadow-chat-status-escalated rounded-[5px] gap-[5px] border border-[#c11a450d] py-2.5 px-4 bg-[#c11a450d]',
          )}
        >
          <p className={'text-[#C11A45]'}>{chat?.friendlyStatus}</p>
          <EscalatedStatusIcon fill={'#C11A45'} selected={chat?.selected} />
        </div>
      )}
    </>
  );
};
