import './ResolvedEscalatedChart.css';
import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const COLORS = { Resolved: '#877CDB', Escalated: '#EDAEBE' };

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
  centered = false,
}) => {
  // if "0" label will be on center of pie, if "1" label will be out of the border
  const FROM_CENTER_TO_BORDER = 0.4;
  const radius = innerRadius + (outerRadius - innerRadius) * FROM_CENTER_TO_BORDER;
  const x = centered ? '50%' : cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      fontWeight="500"
      textAnchor={centered ? 'middle' : x > cx ? 'start' : 'end'}
      dominantBaseline={centered ? 'middle' : 'central'}
      className={'pointer-events-none'}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const backgroundColor = payload[0].payload.fill;

    return (
      <div
        className={
          'bg-white rounded shadow-tooltip text-xs py-1.5 px-3 flex flex-row gap-2 items-center font-jamjuree'
        }
      >
        <div
          style={{
            backgroundColor,
          }}
          className={'w-2 h-2 rounded-[50%]'}
        ></div>
        <div className={'flex flex-col items-start'}>
          <p className="font-medium">{`${payload[0].name}:`}</p>
          <p className="font-bold">{`${payload[0].value}`}</p>
        </div>
      </div>
    );
  }

  return null;
};

export const ResolvedEscalatedChart = ({ resolved = 0, escalated = 0 }) => {
  const [data, setData] = useState([
    { name: 'Resolved', value: resolved },
    { name: 'Escalated', value: escalated },
  ]);

  useEffect(() => {
    const newData = [];

    if (resolved) {
      newData.push({ name: 'Resolved', value: resolved });
    }

    if (escalated) {
      newData.push({ name: 'Escalated', value: escalated });
    }

    setData(newData);
  }, [resolved, escalated]);

  return (
    <ResponsiveContainer
      width="99%"
      height="99%"
      style={{
        position: 'relative',
        textAlign: 'center',
      }}
      aspectRatio={3}
    >
      <Legend
        content={
          <>
            <div className="text-ebony/50 text-sm mt-[20px]">Resolved vs Escalated</div>
          </>
        }
      />

      <PieChart width={500} height={500}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={(args) =>
            renderCustomizedLabel({
              ...args,
              centered: data.length === 1,
            })
          }
          outerRadius={100}
          fill="#8884d8"
          dataKey="value"
          blendStroke={true}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[entry.name]} className={'cursor-pointer'} />
          ))}
        </Pie>
        <Legend wrapperStyle={{ bottom: '20px' }} />
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
};
