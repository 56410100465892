export const ROUTE_PATHS = {
  MAIN: '/',
  RESET_PASSWORD: 'reset-password',
  EMAIL_VERIFY: 'email-verify',
  UNINSTALL_EXTENSION: 'uninstall-extension',
  DASHBOARD: 'dashboard',
  ACCOUNT: 'account',
  PROJECTS: 'projects',
  SUBSCRIPTION: 'subscription',
  AUTH: '/auth',
  REGISTER: '/register',
  LOGIN: '/login',
  DASHBOARD_PROJECT: '/dashboard/projects/:id',
  DASHBOARD_PROJECT_NEW: '/dashboard/projects/new',
  DASHBOARD_PROJECT_ANALYTICS: '/dashboard/projects/:id/analytics',
  DASHBOARD_PROJECT_INTEGRATIONS: '/dashboard/projects/:id/integrations',
  DASHBOARD_PROJECT_KNOWLEDGE_BASE: '/dashboard/projects/:id/knowledge-base',
  DASHBOARD_PROJECT_CONVERSATIONS: '/dashboard/projects/:id/conversations',
};
