import { MarkdownRenderer } from 'src/components/MarkdownRenderer/MarkdownRenderer';
import { clsx } from 'clsx';
import moment from 'moment';
import { Separator } from 'src/components/ui/separator';

const LAST_MESSAGE = {
  RESOLVED: 'End of the chat.',
  ESCALATED: 'Connected to the operator. Please, wait a moment.',
};

const filterMessage = (message) => {
  if (message.body.includes(LAST_MESSAGE.RESOLVED)) {
    return false;
  }
  if (message.body.includes(LAST_MESSAGE.ESCALATED)) {
    return false;
  }

  return true;
};

export const ConversationChat = ({ chat }) => {
  const isResolved = ['closed', 'auto_closed'].includes(chat.status);
  const isEscalated = chat.status === 'escalated';

  return (
    <>
      <ul className={'flex flex-col gap-[5px] h-[100%] pr-[15px] overflow-auto'}>
        {chat.messages.filter(filterMessage).map((message) => {
          const isUser = message.owner === 'user';

          return (
            <li
              key={`message-${message._id}`}
              className={clsx(
                'list-none flex flex-col text-sm font-medium text-ebony_opacity_70',
                isUser ? 'items-start' : 'items-end',
              )}
            >
              <div
                className={clsx(
                  'lg:max-w-[80%] flex flex-col',
                  isUser ? 'items-start' : 'items-end',
                )}
              >
                <div
                  className={clsx(
                    'rounded-md-10 p-4 max-w-full',
                    isUser ? 'bg-light' : 'bg-[#EEECFD]',
                  )}
                >
                  <MarkdownRenderer skipHtml={true}>{message.body}</MarkdownRenderer>
                </div>
                <div className={'text-[11px] text-ebony_opacity_30 font-medium'}>
                  <p>{moment(message.created_at).format('lll')}</p>
                </div>
              </div>
            </li>
          );
        })}
        {isResolved && (
          <li
            className={
              'list-none flex ext-sm font-medium flex-row w-full justify-center items-center relative mt-4'
            }
          >
            <div
              className={
                'py-2 px-4 rounded-3xl border border-[#389D61] text-xs text-[#389D61] bg-white z-10'
              }
            >
              Resolved – {moment(chat.updated_at).format('lll')}
            </div>
            <Separator className={'bg-[#389D61] w-full absolute top-[50%]'} />
          </li>
        )}
        {isEscalated && (
          <li
            className={
              'list-none flex ext-sm font-medium flex-row w-full justify-center items-center relative mt-4'
            }
          >
            <div
              className={
                'py-2 px-4 rounded-3xl border border-[#C11A45] text-xs text-[#C11A45] bg-white z-10'
              }
            >
              Escalated – {moment(chat.updated_at).format('lll')}
            </div>
            <Separator className={'bg-[#C11A45] w-full absolute top-[50%]'} />
          </li>
        )}
      </ul>
    </>
  );
};
