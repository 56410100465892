import React, { useEffect } from 'react';
import { Button } from 'src/components/ui/button';
import { clsx } from 'clsx';
import { Checkbox } from 'src/components/ui/checkbox';
import { Separator } from 'src/components/ui/separator';
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group';
import { useClickOutside } from 'src/hooks/useClickOutside';
import { DateRangeCollapsible } from 'src/pages/ConversationsPage/ConversationFilter/DateRangeCollapsible/DateRangeCollapsible';
import moment from 'moment';

const INIT_VALUES = {
  resolved: true,
  escalated: true,
  inProgress: true,
  timeRange: 'all',
  dateRange: {
    from: moment().toDate(),
    to: moment().toDate(),
  },
};

export const ConversationFilter = ({ request, onChange = () => {} }) => {
  const [resolved, setResolved] = React.useState(INIT_VALUES.resolved);
  const [escalated, setEscalated] = React.useState(INIT_VALUES.escalated);
  const [inProgress, setInProgress] = React.useState(INIT_VALUES.inProgress);
  const [timeRange, setTimeRange] = React.useState(INIT_VALUES.timeRange);
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState(INIT_VALUES.dateRange);
  const [dateRangeOpen, setDateRangeOpen] = React.useState(false);
  const popoverRef = React.useRef(null);
  const buttonRef = React.useRef(null);

  useClickOutside(popoverRef, (event) => {
    if (
      open &&
      event.target !== buttonRef.current &&
      !['svg', 'path'].includes(event.target.tagName)
    ) {
      setOpen(false);
    }
  });

  const onTimeRangeChange = (e) => {
    setTimeRange(e || e.currentTarget.value);
  };

  const closePopover = (resetToDefault) => {
    resetToDefault && resetState();
    setOpen(false);
  };

  const resetState = () => {
    setResolved(INIT_VALUES.resolved);
    setEscalated(INIT_VALUES.escalated);
    setInProgress(INIT_VALUES.inProgress);
    setTimeRange(INIT_VALUES.timeRange);
    setDateRange(INIT_VALUES.dateRange);
  };

  useEffect(() => {
    setDateRangeOpen(timeRange !== INIT_VALUES.timeRange);
  }, [timeRange]);

  useEffect(() => {
    setInProgress(request.chat_status.includes('open'));
    setResolved(request.chat_status.includes('closed'));
    setEscalated(request.chat_status.includes('escalated'));

    if (!request.created_at_start && !request.updated_at_start) {
      setTimeRange(INIT_VALUES.timeRange);
    }
  }, [request]);

  const onDateRangeChange = ({ from, to }) => {
    setDateRange({
      from: moment(from).startOf('day').toDate(),
      to: moment(to).endOf('day').toDate(),
    });
  };

  const apply = () => {
    const data = {
      chat_status: [],
      created_at_start: '',
      created_at_end: '',
      updated_at_start: '',
      updated_at_end: '',
    };

    if (resolved) {
      data.chat_status.push(...['closed', 'auto_closed']);
    }

    if (escalated) {
      data.chat_status.push('escalated');
    }

    if (inProgress) {
      data.chat_status.push('open');
    }

    if (timeRange !== INIT_VALUES.timeRange) {
      data[`${timeRange}_start`] = moment(dateRange.from)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      data[`${timeRange}_end`] = moment(dateRange.to).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    }

    onChange(data);
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outline"
        size="sm"
        className={clsx('shadow-search gap-2')}
        onClick={() => setOpen(true)}
        ref={buttonRef}
      >
        Filters
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M11.805 0H0.180011C0.130937 0.00420664 0.0851092 0.0288781 0.051724 0.0690641C0.0183387 0.10925 -0.000134235 0.161977 1.15866e-05 0.216667V0.933333C-0.000439034 1.00679 0.0122585 1.07961 0.0373659 1.14757C0.0624732 1.21552 0.0994893 1.27725 0.146261 1.32917L4.64626 6.32917V10.4958L7.365 12V6.32083L11.865 1.32083C11.9511 1.21765 11.9993 1.08237 12 0.941667V0.216667C12 0.159203 11.9795 0.104093 11.9429 0.0634603C11.9063 0.0228274 11.8567 0 11.805 0Z"
            fill="#05050D"
            fillOpacity="0.8"
          />
        </svg>
      </Button>
      <div
        ref={popoverRef}
        className={clsx(
          'z-10 absolute border bg-white border-[#E3E6E8] rounded-[5px] top-0 right-0 transition-all duration-100 overflow-hidden',
          open ? 'w-full visible' : '!invisible display-none w-0 overflow-hidden',
        )}
      >
        <div
          className={clsx(
            'flex flex-1 flex-row items-center justify-between py-5 px-6 w-full relative',
            open ? 'flex-wrap' : 'flex-nowrap overflow-hidden',
          )}
        >
          <p className={'text-ebony_opacity_80 text-sm font-semibold min-w-[80px]'}>Status:</p>
          <div className={'flex flex-row items-center gap-1.5'}>
            <Checkbox
              id={`resolved`}
              checked={resolved}
              className={'rounded-[2px]'}
              onCheckedChange={setResolved}
            />
            <label
              className="text-ebony_opacity_50 text-sm font-medium cursor-pointer"
              htmlFor="resolved"
            >
              Resolved
            </label>
          </div>
          <div className={'flex flex-row items-center gap-1.5'}>
            <Checkbox
              id={`escalated`}
              checked={escalated}
              className={'rounded-[2px]'}
              onCheckedChange={setEscalated}
            />
            <label
              className="text-ebony_opacity_50 text-sm font-medium cursor-pointer"
              htmlFor="escalated"
            >
              Escalated
            </label>
          </div>
          <div className={'flex flex-row items-center gap-1.5'}>
            <Checkbox
              id={`inprogress`}
              checked={inProgress}
              className={'rounded-[2px]'}
              onCheckedChange={setInProgress}
            />
            <label
              className="text-ebony_opacity_50 text-sm font-medium cursor-pointer"
              htmlFor="inprogress"
            >
              In Progress
            </label>
          </div>
        </div>
        <Separator className={'bg-[#E3E6E8]'} />
        <RadioGroup
          onChange={onTimeRangeChange}
          onValueChange={onTimeRangeChange}
          defaultValue={timeRange}
          value={timeRange}
          className={clsx(
            'flex flex-1 flex-row items-center justify-between py-5 px-6 w-full relative',
            open ? 'flex-wrap' : 'flex-nowrap overflow-hidden',
          )}
        >
          <p className={'text-ebony_opacity_80 text-sm font-semibold'}>Time Range:</p>
          <div className={'flex flex-row items-center gap-1.5'}>
            <RadioGroupItem value={'all'} id={'allhistory'} />
            <label htmlFor={'allhistory'} className="cursor-pointer">
              <p className="text-ebony_opacity_50 text-sm font-medium opacity-70 hover:opacity-100 transition">
                All History
              </p>
            </label>
          </div>
          <div className={'flex flex-row items-center gap-1.5'}>
            <RadioGroupItem value={'created_at'} id={'created'} />
            <label htmlFor={'created'} className="cursor-pointer">
              <p className="text-ebony_opacity_50 text-sm font-medium opacity-70 hover:opacity-100 transition">
                Created
              </p>
            </label>
          </div>
          <div className={'flex flex-row items-center gap-1.5'}>
            <RadioGroupItem value={'updated_at'} id={'updated'} />
            <label htmlFor={'updated'} className="cursor-pointer">
              <p className="text-ebony_opacity_50 text-sm font-medium opacity-70 hover:opacity-100 transition">
                Last updated
              </p>
            </label>
          </div>
        </RadioGroup>
        <DateRangeCollapsible
          open={dateRangeOpen}
          setOpen={setDateRangeOpen}
          onChange={onDateRangeChange}
          defaultValue={dateRange}
          className={clsx(open ? 'flex-wrap' : 'flex-nowrap overflow-hidden')}
        />
        <Separator className={'bg-[#E3E6E8]'} />
        <div
          className={clsx(
            'flex flex-row items-center justify-center gap-2.5 py-6',
            open ? 'flex-wrap' : 'flex-nowrap',
          )}
        >
          <Button
            variant="outline"
            size="sm"
            className={clsx('shadow-search gap-2 px-8')}
            onClick={() => closePopover(true)}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            size="sm"
            className={clsx('shadow-search gap-2 px-8')}
            onClick={apply}
            disabled={!resolved && !inProgress && !escalated}
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
};
