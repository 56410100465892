import clsx from 'clsx';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

const CloseIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      viewBox="0 0 10 9"
      fill="none"
      {...props}
    >
      <path d="M1 0.5L9 8.5M9 0.5L1 8.5" stroke="#E50505" strokeLinecap="round" />
    </svg>
  );
};

const initialChips = [
  {
    label: 'All Statuses',
    key: 'chat_status',
    values: ['open', 'escalated', 'closed', 'auto_closed'],
    readonly: true,
  },
  {
    label: 'All History',
    readonly: true,
  },
];

export const ConversationChips = ({ request, onChange }) => {
  const [chips, setChips] = useState(initialChips);

  const removeChip = (chip) => {
    const isLastUpdated = chip.label.includes('Last updated:');
    const isCreatedAt = chip.label.includes('Created: ');
    const newRequest = {};

    if (isLastUpdated || isCreatedAt) {
      newRequest.created_at_start = '';
      newRequest.created_at_end = '';
      newRequest.updated_at_start = '';
      newRequest.updated_at_end = '';
    }

    if (chip.key === 'chat_status') {
      newRequest.chat_status = request.chat_status.filter((status) => {
        return !chip.values.includes(status);
      });

      if (!newRequest.chat_status.length) {
        newRequest.chat_status = initialChips[0].values;
      }
    }

    onChange(newRequest);
  };

  useEffect(() => {
    updateChips(request);
  }, [request]);

  const updateChips = (request) => {
    const isInProgress = request.chat_status.includes('open');
    const isClosed = request.chat_status.includes('closed');
    const isEscalated = request.chat_status.includes('escalated');
    const isAllStatuses = isInProgress && isClosed && isEscalated;
    const newChips = [];

    if (isAllStatuses) {
      newChips.push(initialChips[0]);
    }

    if (!isAllStatuses && isInProgress) {
      newChips.push({ label: 'In Progress', key: 'chat_status', values: ['open'] });
    }

    if (!isAllStatuses && isClosed) {
      newChips.push({
        label: 'Resolved',
        key: 'chat_status',
        values: ['closed', 'auto_closed'],
      });
    }

    if (!isAllStatuses && isEscalated) {
      newChips.push({
        label: 'Escalated',
        key: 'chat_status',
        values: ['escalated'],
      });
    }

    if (request.updated_at_start) {
      const date = `${moment(request.updated_at_start).format('ll')} - ${moment(request.updated_at_end).format('ll')}`;
      newChips.push({ label: `Last updated: ${date}` });
    }

    if (request.created_at_start) {
      const date = `${moment(request.created_at_start).format('ll')} - ${moment(request.created_at_end).format('ll')}`;
      newChips.push({ label: `Created: ${date}` });
    }

    if (!request.updated_at_start && !request.created_at_start) {
      newChips.push(initialChips[1]);
    }

    setChips(newChips);
  };

  return (
    <div className={'flex flex-row items-center gap-1.5'}>
      {chips.map((chip) => {
        return (
          <div
            key={chip.label}
            className={clsx(
              'flex flex-row items-center gap-1.5 text-xs border-[#E3E6E8] rounded-3xl py-2 px-2.5 border',
              chip.readonly
                ? 'text-ebony_opacity_30 border-dashed'
                : 'text-ebony border-solid hover:border-ebony_opacity_30',
            )}
          >
            <span className={'font-medium'}>{chip.label}</span>
            {!chip.readonly && (
              <CloseIcon className={'cursor-pointer'} onClick={() => removeChip(chip)} />
            )}
          </div>
        );
      })}
    </div>
  );
};
