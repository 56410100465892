import React from 'react';
import { AnalyticsTabsDiffs } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsTabs/AnalyticsTabsDiffs/AnalyticsTabsDiffs';
import { TabsTrigger } from 'src/components/ui/tabs';
import { clsx } from 'clsx';

export const AnalyticsTabsTrigger = ({
  tabId,
  title = '',
  value = '0',
  diff = 0,
  className,
  oppositTickers = false,
}) => {
  return (
    <TabsTrigger
      className={clsx(
        "TabsTrigger relative flex flex-1 w-[32%] min-w-[32%] max-w-[32%] md:w-[19%] md:min-w-[19%] md:max-w-[19%] sm:w-[24%] sm:min-w-[24%] sm:max-w-[24%] h-full justify-start !shadow-none p-0 !shadow-card data-[state=inactive]:opacity-40 data-[state=inactive]:hover:opacity-100 data-[state=active]:rounded-t-sm data-[state=active]:!rounded-b-none after:content-[' '] after:h-[0px] after:w-full after:absolute after:bottom-0 data-[state=inactive]:after:h-[15px] data-[state=inactive]:after:bg-white after:transition-all",
        className,
      )}
      value={tabId}
    >
      <div
        className={
          'w-full flex flex-col min-h-[130px] max-h-[145px] justify-between items-start flex-grow-0 p-[15px] pt-[20px] pb-[30px] text-wrap'
        }
      >
        <div className={'text-ebony text-sm font-medium leading-[140%] font-jamjuree text-start'}>
          {title}
        </div>
        <div className={'flex flex-row w-full justify-start'}>
          <span className={'text-base font-bold text-ebony leading-5 text-start'}>{value}</span>
          {/*<AnalyticsTabsDiffs diff={diff} oppositTickers={oppositTickers} />*/}
        </div>
      </div>
    </TabsTrigger>
  );
};
