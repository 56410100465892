export const ResolvedStatusIcon = ({ fill, selected, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      {...props}
    >
      <path
        d="M2.62579 7.28518L0 4.65939L1.19662 3.46277L2.62579 4.89617L6.80338 0.714355L8 1.91097L2.62579 7.28518Z"
        fill={fill || '#05050D'}
        fillOpacity={selected ? '0.8' : '0.5'}
      />
    </svg>
  );
};

export const EscalatedStatusIcon = ({ fill, selected, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="10"
      viewBox="0 0 8 10"
      fill="none"
      {...props}
    >
      <path
        d="M1.55586 2.44434C1.55586 1.79606 1.81339 1.17434 2.27179 0.715932C2.73019 0.257529 3.35192 0 4.0002 0C4.64848 0 5.27021 0.257529 5.72862 0.715932C6.18702 1.17434 6.44455 1.79606 6.44455 2.44434C6.44455 3.09263 6.18702 3.71435 5.72862 4.17276C5.27021 4.63116 4.64848 4.88869 4.0002 4.88869C3.35192 4.88869 2.73019 4.63116 2.27179 4.17276C1.81339 3.71435 1.55586 3.09263 1.55586 2.44434ZM0.000366211 8.55525C0.000366211 7.9659 0.234483 7.40069 0.651214 6.98396C1.06794 6.56723 1.63315 6.33312 2.2225 6.33312H5.77791C6.36725 6.33312 6.93246 6.56723 7.34919 6.98396C7.76592 7.40069 8.00004 7.9659 8.00004 8.55525V10H0.000366211V8.55525Z"
        fill={fill || '#05050D'}
        fillOpacity={selected ? '0.8' : '0.5'}
      />
    </svg>
  );
};

export const InProgressStatusIcon = ({ fill, selected, ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      {...props}
    >
      <path d="M0 0V8L6.4 4L0 0Z" fill={fill || '#05050D'} fillOpacity={selected ? '0.8' : '0.5'} />
    </svg>
  );
};
