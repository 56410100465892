import './AnalyticsAgentCustomizePopup.css';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/ui/dialog';
import { clsx } from 'clsx';
import React, { useEffect, useState } from 'react';
import { OpenAnalyticsAgentCustomizePopupIcon } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentCustomizePopup/OpenAnalyticsAgentCustomizePopupIcon';
import { Controller, useForm } from 'react-hook-form';
import { useNotification } from 'src/hooks/useNotification';
import Button from 'src/components/Button/Button';
import { AnalyticsDateRangeAccordion } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentCustomizePopup/AnalyticsDateRangeAccordion/AnalyticsDateRangeAccordion';
import { AnalyticsTimeRangeAccordion } from 'src/pages/AnalyticsPage/AnalyticsAgent/AnalyticsAgentCustomizePopup/AnalyticsTimeRangeAccordion/AnalyticsTimeRangeAccordion';

const DATE_RANGE_OPTIONS = [
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'day' },
  { label: 'Weeks', value: 'week' },
  { label: 'Months', value: 'month' },
];
const TIME_RANGE_OPTIONS = [
  { label: 'Seconds', value: 'seconds' },
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
];

export const AnalyticsAgentCustomizePopup = ({
  request,
  disabled = false,
  onSubmit = () => {},
  className = '',
}) => {
  const [open, setOpen] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    setValue('date_range', getDefaultDateRange());
    setValue('time_axis', getDefaultTimeRange());
  }, [request]);

  const getDefaultDateRange = () => {
    return DATE_RANGE_OPTIONS.find((dateRange) => dateRange.value === request?.date_range);
  };

  const getDefaultTimeRange = () => {
    return TIME_RANGE_OPTIONS.find((timeRange) => timeRange.value === request?.time_axis);
  };

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      date_range: getDefaultDateRange(),
      time_axis: getDefaultTimeRange(),
    },
  });

  const submit = (formValues) => {
    const filteredData = Object.keys(formValues).reduce((acc, key) => {
      if (formValues[key] && formValues[key].value !== request[key]) {
        acc[key] = formValues[key].value;
      }
      return acc;
    }, {});

    if (!Object.keys(filteredData).length) {
      notification.info('Nothing changed');
    } else {
      onSubmit(filteredData);
      setOpen(false);
    }
  };

  const cancel = () => {
    reset({ date_range: getDefaultDateRange(), time_axis: getDefaultTimeRange() });
    setOpen(false);
  };

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger
        disabled={disabled}
        asChild
        className={clsx(
          disabled && 'pointer-events-none opacity-50',
          'cursor-pointer',
          'analytics-agent-customize-popup-trigger',
        )}
      >
        <div className="bg-[#fff] p-[9px] rounded-[4px] cursor-pointer icon-container flex items-center justify-center text-sm font-medium font-jamjuree gap-5">
          Customize
          <OpenAnalyticsAgentCustomizePopupIcon />
        </div>
      </DialogTrigger>
      <DialogContent className={clsx(className, 'md:!max-w-[50vw] md:min-w-[560px]')}>
        <DialogHeader className={'flex-row items-center justify-between'}>
          <DialogTitle className="text-start">
            <div className={'flex flex-row gap-[8px] items-center justify-center'}>
              <span>Customize your analytics</span>
            </div>
          </DialogTitle>
          <DialogClose className={'!m-0 analytics-popup-close-icon'}></DialogClose>
        </DialogHeader>
        <div className="max-md:flex-row max-md:gap-2.5 rounded-[7px] overflow-auto">
          <div className={'w-full h-full'}>
            <form
              id="customize-agent-analytics-form"
              onSubmit={handleSubmit(submit)}
              className={'flex flex-col items-stretch justify-stretch gap-[5px]'}
            >
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="date_range"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <AnalyticsDateRangeAccordion
                      value={value}
                      options={DATE_RANGE_OPTIONS}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              <div className={'input-container'}>
                <Controller
                  control={control}
                  name="time_axis"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <AnalyticsTimeRangeAccordion
                      value={value}
                      options={TIME_RANGE_OPTIONS}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
            </form>
          </div>
        </div>
        <DialogFooter>
          <Button style={{ width: 'auto' }} className="transparent black" onClick={cancel}>
            Cancel
          </Button>
          <Button
            style={{ width: 'auto' }}
            type="submit"
            form="customize-agent-analytics-form"
            className="blue"
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
