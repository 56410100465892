export const EmptyChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="225"
    height="173"
    viewBox="0 0 225 173"
    fill="none"
  >
    <path
      d="M76.5699 35.2705H209.686C212.896 35.2705 215.974 36.5455 218.243 38.8149C220.513 41.0844 221.788 44.1625 221.788 47.372V128.048C221.788 131.258 220.513 134.336 218.243 136.605C215.974 138.875 212.896 140.15 209.686 140.15H203.071V170L169.751 140.15H76.5699C73.3604 140.15 70.2824 138.875 68.0129 136.605C65.7435 134.336 64.4685 131.258 64.4685 128.048V47.372C64.4685 44.1625 65.7435 41.0844 68.0129 38.8149C70.2824 36.5455 73.3604 35.2705 76.5699 35.2705Z"
      fill="#F1F7F6"
      stroke="#F1F7F6"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M148.218 3H15.1015C11.892 3 8.8139 4.27497 6.54444 6.54444C4.27497 8.8139 3 11.892 3 15.1015V95.7779C3 98.9874 4.27497 102.065 6.54444 104.335C8.8139 106.604 11.892 107.879 15.1015 107.879H21.7169V137.73L55.0363 107.879H148.218C151.427 107.879 154.505 106.604 156.775 104.335C159.044 102.065 160.319 98.9874 160.319 95.7779V15.1015C160.319 11.892 159.044 8.8139 156.775 6.54444C154.505 4.27497 151.427 3 148.218 3ZM28.2114 55.4397H135.108H28.2114ZM28.2114 31.2367H135.108H28.2114ZM28.2114 79.6426H94.7695H28.2114Z"
      fill="white"
    />
    <path
      d="M28.2114 55.4397H135.108M28.2114 31.2367H135.108M28.2114 79.6426H94.7695M148.218 3H15.1015C11.892 3 8.8139 4.27497 6.54444 6.54444C4.27497 8.8139 3 11.892 3 15.1015V95.7779C3 98.9874 4.27497 102.065 6.54444 104.335C8.8139 106.604 11.892 107.879 15.1015 107.879H21.7169V137.73L55.0363 107.879H148.218C151.427 107.879 154.505 106.604 156.775 104.335C159.044 102.065 160.319 98.9874 160.319 95.7779V15.1015C160.319 11.892 159.044 8.8139 156.775 6.54444C154.505 4.27497 151.427 3 148.218 3Z"
      stroke="#F1F7F6"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
