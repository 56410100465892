const ROOTS_AUTH = 'v1/auth';
const ROOTS_PROJECTS = 'v1/projects';
const ROOTS_USERS = 'v1/users';
const ROOTS_BILLING = 'v1/billing';
const ROOTS_EXTERNAL = 'v1/external';
const ROOTS_STATISTICS = 'v1/statistics';
export const ACCOUNTS_URL = 'v1/accounts/';

export const PATH_AUTH_API = {
  ROOT: ROOTS_AUTH,
  LOGOUT: `${ROOTS_AUTH}/logout`,
  TOKEN_REFRESH: `${ROOTS_AUTH}/token/refresh`,
};

export const PATH_PROJECTS_API = {
  ROOT: ROOTS_PROJECTS,
  ALL: `${ROOTS_PROJECTS}/all`,
  DEMO_MODE: `${ROOTS_PROJECTS}/project/demo-mode`,
  TEAM_MEMBERS: `team-members`,
  NEW_LIMIT: `new-limit`,
  INTEGRATIONS: `integrations`,
  FILES: `files`,
  ADD_DOCUMENT: `documents`,
  CHATS: `chats`,
};

export const PATH_BILLING_API = {
  ROOT: ROOTS_BILLING,
  PAYMENTS: `${ROOTS_BILLING}/payments`,
  PLANS_ALL: `${ROOTS_BILLING}/plans/all`,
  PAYMENT_METHODS: `${ROOTS_BILLING}/payment-methods`,
  PAYMENT_METHOD_DEFAULT: `${ROOTS_BILLING}/payment-methods/default`,
  CREATE_SUBSCRIPTION: `${ROOTS_BILLING}/create-subscription`,
  CHANGE_SUBSCRIPTION: `${ROOTS_BILLING}/change-subscription`,
  CANCEL_SUBSCRIPTION: `${ROOTS_BILLING}/cancel-subscription`,
  INVOICE_PDF: `${ROOTS_BILLING}/invoice/pdf`,
  SUBSCRIPTION_PERMISSION_GET: `${ROOTS_BILLING}/project-plans/subscription/permission/get`,
  SUBSCRIPTION_PERMISSION_SET: `${ROOTS_BILLING}/project-plans/subscription/permission/set`,
  CREATE_PAYMENT_INTENT: `${ROOTS_BILLING}/payment-intent/create`,
};

export const PATH_USERS_API = {
  ROOT: ROOTS_USERS,
  ME: `${ROOTS_USERS}/me`,
  ALL: `${ROOTS_USERS}/all`,
  CREATE: `${ROOTS_USERS}/create`,
  UPDATE: `/update`,
  DELETE: `/delete`,
};

export const PATH_ANALYTICS_API = {
  COAGENT: `${ROOTS_EXTERNAL}/statistics`,
  COCUSTOMER: {
    ROOT: `${ROOTS_STATISTICS}/cocustomer`,
    TIME_SERIES: `${ROOTS_STATISTICS}/cocustomer/timeseries`,
    AGGREGATED: `${ROOTS_STATISTICS}/cocustomer/aggregated`,
    CHATS_COUNT: `${ROOTS_STATISTICS}/cocustomer/chats/count`,
  },
};
