import React, { useEffect } from 'react';
import CardContainer from 'src/components/CardContainer/CardContainer';
import { PaymentDetailsPopup } from './PaymentDetailsPopup/PaymentDetailsPopup';
import { useGetAccountsQuery } from 'src/services/accountsService';
import { transformDateHelper } from 'src/helpers/transformDateHelper';
import { DateFormat } from 'src/constants/dateTimeFormatOption';
import { LoaderWrapper } from 'src/components/LoaderWrapper/LoaderWrapper';
import { Skeleton } from 'src/components/ui/skeleton';

const DEFAULT_VALUE = {
  generalPlan: {},
  cost: 0,
  extra: 0,
};

export const PaymentDetailsCard = () => {
  const [subscriptionsData, setSubscriptionsData] = React.useState(DEFAULT_VALUE);

  const { data: accounts, isFetching: accountIsFetching } = useGetAccountsQuery();

  const projectsReducedValueHandler = () => {
    const projGeneralPlan = accounts?.general_plan;
    const projPlan = accounts?.general_plan?.plan;
    const projPlanCustom = accounts?.general_plan?.custom_plan_settings;

    const extraPriceDefault = projGeneralPlan?.credits_count * projGeneralPlan?.extra_request_price;
    const extraPriceCustom = projGeneralPlan?.credits_count * projPlanCustom?.usage_price;

    const cost = projPlanCustom?.fixed_price || projPlan?.price || 0;
    const extraPrice = extraPriceCustom || extraPriceDefault || 0;

    return {
      generalPlan: projGeneralPlan,
      cost: cost,
      extra: extraPrice.toFixed(2),
    };
  };

  useEffect(() => {
    if (accounts?.general_plan) {
      setSubscriptionsData(projectsReducedValueHandler());
    } else {
      setSubscriptionsData(DEFAULT_VALUE);
    }
  }, [accounts]);

  const isDisabled =
    subscriptionsData.generalPlan && !Object.keys(subscriptionsData.generalPlan)?.length;
  const extraReply = {
    cost: Number(subscriptionsData?.cost) || 0,
    extra: Number(subscriptionsData?.extra) || 0,
  };

  return (
    <CardContainer className={`half-width flex-col justify-between`}>
      <div className="card-header">
        <div className="title">
          <p className="text-ebony font-bold text-sm">Next Payment</p>
        </div>
        <div className="edit-button-container">
          <PaymentDetailsPopup
            accounts={accounts}
            subscriptionsData={subscriptionsData}
            disabled={isDisabled || accountIsFetching}
          />
        </div>
      </div>
      <div className="card-body  h-full py-1">
        <div
          className={
            'input-container full-width border rounded-[7px] h-full px-4 py-3.5 flex justify-between items-end'
          }
        >
          <LoaderWrapper
            loader={
              <div className="flex justify-between w-full items-end">
                <Skeleton className="h-7 w-14" />
                <Skeleton className="h-3.5 w-20" />
              </div>
            }
            isLoading={accountIsFetching}
          >
            <p className="md:text-base text-xl md:font-medium font-bold text-ebony">
              {(extraReply.cost + extraReply.extra)?.toFixed(2) || 0} $
            </p>
            {accounts?.stripe_subscription_end && (
              <p className="text-xs font-medium text-ebony md:opacity-50 opacity-70">
                On{' '}
                {transformDateHelper(
                  accounts?.stripe_subscription_end,
                  DateFormat.MONTH_DAY_YEAR_WITHOUT_COMA,
                )}
              </p>
            )}
          </LoaderWrapper>
        </div>
      </div>
    </CardContainer>
  );
};
