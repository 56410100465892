import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'src/components/ui/accordion';
import { clsx } from 'clsx';
import React from 'react';
import { AccordionIcon } from 'src/assets/icons/AccordionIcon';

export const AnalyticsDateRangeAccordion = ({ value, options = [], onChange, disabled }) => {
  return (
    <>
      <p className={'text-grey_text text-xs font-medium mb-1'}>Date range (x)</p>
      <Accordion disabled={disabled} type="single" collapsible className="w-full">
        <AccordionItem value="companySelect" className="border bg-white rounded-[7px]">
          <AccordionTrigger
            hideDefaultIcon
            className={clsx(
              !disabled
                ? '[&_p]:hover:opacity-100 [&_svg:last-child]:hover:opacity-100'
                : 'opacity-40',
              'py-4 px-5 rounded w-full [&[data-state=open]]:bg-white [&_svg:first-child]:hover:opacity-100 [&_svg:first-child]:opacity-50 [&_p]:transition-all [&_svg]:transition-all [&[data-state=open]_p]:opacity-100 [&[data-state=open]_svg]:opacity-100 [&_svg:last-child]:opacity-50 [&_p]:opacity-60 [&[data-state=open]_svg]:rotate-180',
            )}
          >
            <div className="flex flex-1 justify-between items-center">
              <p className="text-ebony text-base font-medium capitalize">{value.label}</p>
              <AccordionIcon fill="#FFFFFF99" fillInner={'#05050D'} />
            </div>
          </AccordionTrigger>
          <AccordionContent className={clsx('pb-0 [[data-state=open]>&]:bg-transparent')}>
            <div className="flex flex-col relative">
              {options.map((option, index) => (
                <li
                  key={`date-range-${option.value}-${index}`}
                  className={'flex flex-col border-t'}
                >
                  <label
                    htmlFor={`date-range-${option.value}-${index}`}
                    className={
                      'flex flex-row pl-10 pt-4 pb-4 pr-5 items-center gap-2.5 cursor-pointer hover:bg-ebony_opacity_10'
                    }
                  >
                    <input
                      name={'date-range'}
                      value={option.value}
                      id={`date-range-${option.value}-${index}`}
                      type="radio"
                      checked={value.value === option.value}
                      onChange={() => onChange(option)}
                      className={'w-auto accent-ebony'}
                    />
                    <p className={'text-base text-ebony'}>{option.label}</p>
                  </label>
                </li>
              ))}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};
