import './DateRangeCollapsible.css';
import { Root, Content } from '@radix-ui/react-collapsible';
import { Calendar } from 'src/components/ui/calendar';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { stringScheme } from 'src/utils/validation-schemes';
import { cn } from 'src/lib/utils';
import { buttonVariants } from 'src/components/ui/button';
import moment from 'moment/moment';

export const DateRangeCollapsible = ({
  open = false,
  setOpen,
  onChange,
  defaultValue,
  className,
}) => {
  const [monthState, setMonthState] = useState(
    moment(defaultValue.to).subtract(1, 'months').toDate(),
  );
  const { watch, handleSubmit, control, setValue } = useForm({
    defaultValues: {
      date: {
        from: defaultValue.from,
        to: defaultValue.to,
      },
    },
    resolver: yupResolver(
      object({
        date: object({
          from: stringScheme({ required: false }).label('Date from'),
          to: stringScheme({ required: false }).label('Date to'),
        }),
      }),
    ),
  });

  const date = watch('date');

  useEffect(() => {
    onChange(date);
  }, [date]);

  return (
    <Root className="CollapsibleRoot" open={open} onOpenChange={setOpen}>
      <Content className={cn('CollapsibleContent', className)}>
        <form id="aply-date-filter-form">
          <div className="flex gap-2.5 items-center justify-stretch flex-wrap">
            <Controller
              control={control}
              name="date"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Calendar
                  initialFocus
                  mode="range"
                  month={monthState}
                  onMonthChange={setMonthState}
                  disabled={{ after: new Date() }}
                  defaultMonth={value?.from}
                  defaultValues={defaultValue}
                  selected={value}
                  onSelect={onChange}
                  numberOfMonths={2}
                  className={'flex flex-col w-full'}
                  classNames={{
                    head_row: 'flex justify-center',
                    row: 'flex w-full mt-2 justify-center',
                    day_selected:
                      'bg-purple_opacity_20 text-ebony hover:text-ebony focus:purple_light focus:text-ebony',
                    day_today: 'bg-purple_opacity_20 text-accent-foreground',
                    day_range_end: 'day-range-end !bg-purple',
                    day_range_start: 'day-range-start !bg-purple',
                    day_range_middle:
                      'aria-selected:bg-purple_opacity_20 aria-selected:text-accent-foreground',
                    caption: 'flex justify-center relative items-center',
                    caption_label: 'text-xs lg:text-sm font-bold',
                    table: 'w-full border-collapse mt-2.5',
                    head_cell:
                      'text-ebony rounded-[1px] w-6 lg:w-7 font-semibold text-xs lg:text-sm',
                    months:
                      'flex flex-col lg:flex-row space-y-4 lg:gap-2.5 lg:space-x-4 lg:space-y-0 lg:justify-center flex-wrap',
                    month: 'bg-light p-3.5 rounded !m-0',
                    cell: 'h-6 lg:h-7 w-6 lg:w-7 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-[1px] [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-[1px] last:[&:has([aria-selected])]:rounded-r-[1px] focus-within:relative focus-within:z-20',
                    day: cn(
                      buttonVariants({ variant: 'ghost' }),
                      'h-6 lg:h-7 w-6 lg:w-7 p-0 font-normal aria-selected:opacity-100 hover:bg-purple_light text-xs lg:text-sm rounded-[1px]',
                    ),
                  }}
                />
              )}
            />
          </div>
          <div className="flex gap-2.5 items-center justify-center flex-wrap p-3">
            <div
              className={
                'rounded-3xl py-2 px-4 border border-purple text-purple text-xs font-medium font-jamjuree'
              }
            >
              {date && moment(date.from).format('ll')} - {date && moment(date.to).format('ll')}
            </div>
          </div>
        </form>
      </Content>
    </Root>
  );
};
